import React from "react";
import Layout from "../../../components/layout.js";
import Section from "../../../components/section.js";
import Cta from "../../../components/cta.js";
import Columns from "../../../components/columns.js";
import Paragraphleft from "../../../components/paragraphleft.js";
import Paragraphright from "../../../components/paragraphright.js";
import Pricing3 from "../../../components/pricing3.js";
import Masthead from "../../../components/masthead.js";

const services   = [{
    text: "Websites",
    url:  "/websites",
    image:"https://source.unsplash.com/hGV2TfOh0ns/264x140",
    description:"Great websites for every budget, affordable but not cheap. We believe you should only pay for the features you really need today and extend possibilities along the way.",
},{
    text: "Apps",
    url:  "/apps",
    image:"https://source.unsplash.com/hpjSkU2UYSU/264x140",
    description:"Advanced web applications, mobile apps and custom enterprise solutions are what we do best."
},{
    text: "Hosting",
    url:  "/hosting",
    image:"https://source.unsplash.com/ISG-rUel0Uw/264x140",
    description:"We provide that secure home every great website and app needs to live online. Static HTML, PHP, Node.js, Docker & more. We'll work with your budget and reach to scale as your business grows."
},{
    text: "Marketing",
    url:  "/marketing",
    image:"https://source.unsplash.com/DfMMzzi3rmg/264x140",
    description:"Great products and great services deserve to be found. We are here to build your community and help you get those leads."
},{
    text: "E-Learning",
    url:  "/e-learning",
    image:"https://source.unsplash.com/26MJGnCM0Wc/264x140",
    description:"Custom courses, authoring tools, LMS, SCORM/xAPI. We have the experience to make your ideal training project come to life."
},{
    text: "Hardware",
    url:  "/hardware",
    image:"https://source.unsplash.com/3GUW88tRmv8/264x140",
    description:"We have a passion for custom built PCs and servers. We can work with very low budgets or implement extensive workstations and gaming systems."
}];

export default () =>
{
    return (<Layout i18n="en" languages={[]} menulinks={[]}>
        <Masthead illustration="streamline-ux-free/multicolor/svg/monitor-window.svg"
                  title="Websites"
                  subtitle="Websites"
                  cta1txt="Website cost calculator"
                  cta1url="/websites/cost-calculator"
                  cta2txt="Get in touch"
                  cta2url="/contact"
        />
        <Section>
            <Columns illustration=""
                        title="Who can use Evie?"
                        txt="Here's who we can think of, but surely creative people will surprise us."
                        btns={
                        [
                            {
                                img:"https://evie.undraw.co/images/undraw_designer.svg",
                                alt:"Programmers",
                                h:"Programmers",
                                p:"A landing page for your open source projects. Present your solution, easily create docs."
                            },
                            {
                                img:"https://evie.undraw.co/images/undraw_responsive.svg",
                                alt:"Designers",
                                h:"Designers",
                                p:"A website for your own freebies. Share your artwork and let the world use it."
                            },
                            {
                                img:"https://evie.undraw.co/images/undraw_creation.svg",
                                alt:"Makers",
                                h:"Makers",
                                p:"A great starting point for your web application. Focus on your idea and execution."
                            }
                        ]}
                        first={true}
            />
            <Paragraphright
                img="https://evie.undraw.co/images/undraw_browser.svg"
                h="Natural styling without a learning curve"
                txt="Evie brings you the pages you'll need and the structure to create your own, with almost nothing to learn. It is minimal and mostly styles plain elements. There are only a few classes you'll need to know but nothing to disrupt your preferred coding style."
            />
            <Paragraphleft
                img="https://evie.undraw.co/images/undraw_frameworks.svg"
                h="Framework agnostic. Your front-end setup, your choice."
                txt="Evie has zero dependencies and uses vanilla JavaScript for a few functions with minimal footprint. You can use React, Vue, Angular, jQuery or whatever you prefer."
            />
            <Paragraphright
                img="https://evie.undraw.co/images/together.svg"
                h="Ready for production or rapid prototyping"
                txt="Landing, authentication and a few other pages to select from, despite the small size. Tested on production at unDraw with great loading times and unopinionated on how to structure your project. We really hope you'll find it awesome and useful!"
            />
            <Pricing3/>
            <Cta illustration=""
                 title="Get started now"
                 txt="Grab the production version and begin your project instantly."
                 url="/"
                 cta="Download"
                 last={true}
            />
        </Section>
    </Layout>);
}
